<template>
  <div />
</template>

<script>

// import { getUserData } from '@/auth/utils'

export default {
  components: {
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // // data
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data
    //
    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    // const userData = getUserData()
    // console.log(userData.fullName)
    // this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    // })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
